.board_member {

	.info {
		margin: 50px 0;
    padding-bottom: 25px;

    p {
    	text-align: justify;
    }
	}

	.bg {
		height: 330px;
		background-size: contain;
	}

}