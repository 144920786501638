.board {
    .board-inner {
        padding: 0 40px;
        margin-top: 40px;
    }
    h3 {
        height: 30px;
        padding: 0 30px;
        margin-bottom: 30px;
        color: white;
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        text-decoration: none;
        white-space: nowrap;
        background-color: $red;
        color: white;
    }
    .bg {
        width: 100%;
        height: 200px;
        background-image: url(../img/board.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 29%;
    }
    .members {
        width: 100%;
        .member {
            list-style: none;
            lost-column: 1/2 auto 40px;
            margin-bottom: 1.75em;
            &__role {
                font-size: 14px;
                font-style: italic;
                text-align: center;
                display: block;
                margin-bottom: 3px;
                color: $red;
            }
            figure {
                margin: 0;
                position: relative;
                padding-bottom: 40px;
                img {
                    margin: 0 auto;
                    border: 1px solid #d6d7d6;
                    padding: 1px;
                    transition: border-color .3s;
                    /*&:hover {
                        border: 1px solid $red;
                    }*/
                }
                figcaption {
                    font-size: 15px;
                    font-style: italic;
                    text-align: center;
                    padding-top: 3px;
                    line-height: 1.25em;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }
            }
        }
    }
    @media (min-width: 500px) {
        .bg {
            background-position: 50% 29%;
            height: 350px;
        }
        .members {
            .member {
                lost-column: 1/3 auto 40px;
            }
        }
    }
    @media (min-width: $lg) {
        .bg {
            height: 200px;
        }
        .members {
            .member {
                lost-column: 1/2 auto 40px;
            }
        }
    }
}
