.contact {

	&__inner {
		padding: 35px 10% 0;
        text-align: center;
        
        span {
            display: inline-block;
            margin-right: 5px;
        }

		a {
			display: inline-block;
			text-align: center;
			margin-bottom: 5px;
		}
	}

    &__line {
        text-align: center;
        &:last-of-type {
            a {
                margin-bottom: 0;
                margin-top: 25px;                
            }
        }
    }

	&__bg {
        width: 100%;
        height: 200px;
        background-image: url(../img/contact.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media (min-width: 500px) {
        &__bg {            
            height: 350px;
        }        
    }
    @media (min-width: $lg) {
        &__bg {
            height: 210px;
        }        
    }

}