.cs-en {
	&__col {
		@media(min-width: 600px) {
			lost-column: 1/2;
		}
		h2 {
			font-style: normal;
			padding-top: 0;
		}
		.text {
			padding: 20px 8% 50px;
		}
		cite {
			color: $red;
		}
	}
}