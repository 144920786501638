.info {

	&__inner {
		padding: 35px 10% 0;

        span {
            display: inline-block;
        }

		a {
			display: inline-block;
			/*text-align: center;*/
			margin-bottom: 25px;
		}
	}

	&__bg {
        width: 100%;
        height: 200px;
        background-image: url(../img/info.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media (min-width: 500px) {
        &__bg {            
            height: 350px;
        }        
    }
    @media (min-width: $lg) {
        &__bg {
            height: 210px;
        }        
    }

}