.registration {

	&__inner {
		padding: 35px 10% 0;
        
        em {
            color: $red;
        }

        hr {
            margin-bottom: 35px;
        }
	}

    &__heading {
        height: 30px;
        line-height: 30px;
        background-color: $red;
        color: white;
        text-align: center;
        margin-bottom: 35px;
    }

    &__line {
        text-align: center;
    }

	&__bg {
        width: 100%;
        height: 200px;
        background-image: url(../img/registration.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media (min-width: 500px) {
        &__bg {            
            height: 350px;
        }        
    }
    @media (min-width: $lg) {
        &__bg {
            height: 210px;
        }        
    }

}