.partners {

	&__inner {
		padding: 35px 10% 0;        
        
	}
    

	&__bg {
        width: 100%;
        height: 200px;
        background-image: url(../img/partners.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media (min-width: 500px) {
        &__bg {            
            height: 350px;
        }        
    }
    @media (min-width: $lg) {
        &__bg {
            height: 210px;
        }        
    }

}