.rsvp {
    padding: 0 5px;
    margin-bottom: 10px;
    .inner {
        lost-utility: clearfix;
    }
    h3 {
        margin: 35px 0;
        text-transform: uppercase;
        font-weight: bold;
        font-style: italic;
    }
    .buttons {
        text-align: left;
        width: 100%;
        > * {
            height: 30px;
            line-height: 30px;
            margin-right: 30px;
            /*lost-column: 1/3 auto 30px;*/
        }
    }
    [class^="guest"] {
        width: 100%;
        > a {
            text-align: left;
            padding: 0;
            padding-left: 10px;
        }
        .delete-guest {
            font-size: 14px;
            color: #939598;
            position: relative;
            transition: color .3s;
        }
        .delete-guest:hover,
        .delete-guest:hover:after {
            cursor: pointer;
            color: $red;
            border-color: transparent transparent transparent $red;
        }
        .delete-guest:after {
            content: "×";
            position: absolute;
            right: -17px;
            font-size: 18px;
            top: -2px;
            transition: border-color .3s;
        }
        li {
            list-style: none;
            label {
                display: none;
            }
            input {
                width: 100%;
            }
        }
    }
    @media(min-width: 450px) {
    	[class^="guest"] {
    		lost-column: 1/2 auto 30px;	
    	}
    }

    @media(min-width: 750px) {
    	[class^="guest"] {
    		lost-column: 1/3 auto 30px;	
    	}	
    	.buttons {	        
	        > * {
	            lost-column: 1/3 auto 30px;
	        }
	    }
    }
}
