.archive {
	
	.bg {
		width: 100%;
		height: 210px;
		background-image: url(../img/archive.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
	}

	& .archive-inner {
		padding: 0 20px;
		margin-top: 40px;

		& .year {

			& h3 {
				width: calc(100% - 20px);
				margin: 20px auto;
				padding-left: 10px;
				box-shadow: none;
				height: 30px;
				line-height: 30px;
				border-right: 30px solid $red;

				&:after {
					right: -18px;		   
			    margin-top: -5px;		    
			    border-width: 5px 0 5px 5px;
			    transform: rotate(90deg);
				}

				&.open:after {
					transform: rotate(-90deg);
				}

			}

			ul {
				text-align: center;
				list-style: none;
				display: none;
				margin: 0;
				margin-bottom: 20px;

				li {
					font-style: italic;
    			line-height: 40px;
    			padding-right: 20px;
    			margin-bottom: 0;
				}
				
			}

			hr {
				margin: 0
			}

		}

		
	}

}

.sm-bg .archive {
	
	.bg {		
		height: 170px;		
	}

}