.register-form {
    padding: 0 4%;
    li {
        list-style: none;
        > label {
            display: none;
            font-weight: normal;
            color: $red;
            margin-top: 1em;
        }
        &:first-child,
        &:nth-child(10),
        &:nth-child(13) {
            label {
                display: block;
            }
        }

        &:nth-child(13) {
            .InputfieldContent {
                display: none;
            }
        }

        &.Inputfield_sendemail {
            display: none;
        }
        & .InputfieldError  {
        	margin-bottom: 3px;
    		font-weight: bold;
    		font-style: italic;
    		color: $red;
    		font-size: 14px;

    		& + input {
    			/*border-color: $red;*/
    		}
        }
        &:last-child {
            &:after {
                content: "";
                display: table;
                clear: both;
            }
            button {
                float: right;
                height: 30px;
    			line-height: 30px;
    			font-size: 14px;
    			margin-top: 15px;
    			margin-bottom: 45px;
            }
        }
        input[type="text"],
        input[type="email"] {
        	width: 100%;
        }
    }
    .InputfieldRadiosStacked {
        margin: 0;
        margin-left: 1em;
        li {
            display: inline-block;
            margin-left: 3em;
            margin-bottom: 0;
            &:first-child {
                margin: 0;
            }
            span {
                color: $font;
                font-style: italic;
            }
            input {
                margin: 0;
                margin-right: .5em;
            }
            label {
                margin-top: 0;
            }
        }
    }

    &__heading {
    	margin: 35px auto;
        max-width: 75%;
	    text-transform: uppercase;
	    font-weight: bold;
	    font-style: italic;
    }

    @media(min-width: 600px) {
    	max-width: 75%;
    	margin: 0 auto;
    }
}
