.events {
    display: flex;
    flex-wrap: wrap;
}

.event {
    margin-bottom: 40px;
    background-color: white;
    list-style: none;
    border-top: 2px solid $red;
    box-shadow: 1px 1px 5px 1px hsl(0, 0%, 80%);
    position: relative;
    padding-top: 104px;
    width: 100%;
    > a,
    .bg {
        width: 100%;
        height: 210px;
    }
    > h2 {
        width: 100%;
        min-height: 104px;
        position: absolute;
        top: 0;
    }
    .bg {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        position: relative;
        .event-type {
            position: absolute;
            bottom: 0;
            background-color: $red;
            color: white;
            padding: 0px 20px 0 30px;
            left: -10px;
            font-size: 13px;
            font-weight: 400;
        }
        .event-type:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 10px solid $reddark;
            border-left: 7px solid transparent;
            bottom: -8px;
            left: 1.5px;
            transform: rotate(270deg);
        }
    }
    &__more {
        text-decoration: none;
        display: block;
    }
    &.info,
    &.about,
    &.registration,
    &.partner {
        padding-top: 69px;
    }
    .info {
        padding: 4% 4% 0;
        width: 100%;
        .top-text {
            text-align: center;
        }
        span {
            display: inline-block;
            font-style: italic;
            color: $red;
        }
        span.date {
            text-align: center
        }
        span.place {
            text-align: center
        }
        span.date:after {
            content: ', ';
        }
        p {
            margin-top: 18px;
            margin-bottom: 0;
            padding: 0 10px;
            text-align: justify;

        }
        & .success {
            padding-bottom: 15px;
            margin-top: -80px;
        }
        a {
            font-size: 16px;
            padding: 8px 0;
            text-decoration: underline;
            color: $font;
            &:hover {
                text-decoration: none;
            }
            &.back {
                color: #939598;
                display: block;
                text-align: center;
                text-decoration: none;
            }
        }
        ul {
            padding-left: 1em;
            margin-top: 1em;
        }
        a:hover {
            color: $red;
        }
        hr {
            margin-bottom: 30px;
            margin-top: 23px;
        }
        hr.bottom {
            margin-bottom: 0px;
            margin-top: 23px;
        }
        .msg {
            margin-top: 0;
            color: $red;
            font-style: italic;
            font-size: 14px;
        }
    }
    .text {
        padding: 50px 4%;
        hr {
            margin-bottom: 30px;
            margin-top: 23px;
        }
        hr.bottom {
            margin-bottom: 0px;
            margin-top: 23px;
        }
    }
    &.partner {
        .partner__info {
            hr {
                margin-top: 0px !important;
            }
            .bg {
                background-size: contain;
            }
        }
    }

    &.info {
        .bg {
            position: relative;
        }

        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &.registration {
        .bg {
            background-position: 0% 20%;
        }
    }

}

.event.recommended {
    border-top: 2px solid $blue;
    .bg {
        .event-type {
            background-color: $blue
        }
        .event-type:before {
            border-bottom: 10px solid $bluedark;
        }
    }
}

.event.news {
    border-top: 2px solid $green;
    .bg {
        .event-type {
            background-color: $green
        }
        .event-type:before {
            border-bottom: 10px solid $greendark;
        }
    }
    .date,
    .place {
        display: none !important;
    }
}

.event.registration {
	.info {
		p {
			text-align: justify;
			-ms-text-align-last: left;
			text-align-last: left;
		}
	}
}

.two-columns .event {
    align-content: space-between;
    display: flex;
    flex-wrap: inherit;
    > a,
    .bg {
        height: 170px;
    }
    .info {
        span {
            display: block;
        }
        span:first-child {
            /*margin-top: 25px;*/
        }
        span:first-child:after {
            content: '';
        }
        p {
            margin-top: 12px;
            margin-bottom: 0;
            padding: 0 10px;
            text-align: center;
        }
        hr {
            margin-bottom: 0;
            margin-top: 14px;
        }
    }
}

@media (min-width: $sm) {
    .two-columns .event {
        lost-column: 1/2;
    }
}
