@lost gutter 40px;

$red: #e61840;
$reddark: #87001d;
$blue: #298fd6;
$bluedark: #17547c;
$green: #78c513;
$greendark: #4c822a;
$font: #58595b;
$buttongrey: #808285;
$darkgrey: #333132;
$bodygrey: #e6e7e8;
$footergrey: #333132;
$inputgrey: #f1f2f2;

$sm: 568px;
$md: 768px;
$lg: 1024px;



@import 'normalize';
@import 'skeleton';
@import 'global';
@import 'header';
@import 'hero';
@import 'event';
@import 'calendar';
@import 'archive';
@import 'prefooter';
@import 'footer';
@import 'rsvp';
@import 'gallery';
@import 'featherlight';
@import 'about';
@import 'board';
@import 'board-member';
@import 'register-form';
@import 'info';
@import 'contact';
@import 'registration';
@import 'partners';
@import 'cs-en';