@font-face {
    font-family: copper;
    src: url(../font/copper.woff);
}

* {
	box-sizing: border-box;
}

body {	
	background-color: $bodygrey;
}

main {
	margin-top: 60px;
}

aside {
	margin-top: 60px;	
}

img {
	max-width: 100%;
	display: block;
}

.button--animated {
	max-width: 350px;
	display: block;
  	margin: 0 auto 50px;
	height: 70px;
	border-right: 70px solid $red;
	background-color: white;
	color: black;
	width: 100%;
	font-size: 17px;
	font-weight: bold;
	line-height: 70px;
	text-transform: uppercase;
	padding: 0;
	position: relative;
	z-index: 1;
	box-shadow: 1px 1px 5px 1px hsl(0, 0%, 80%);
    overflow: visible;

	&:after {
		content: "";
		position: absolute;
		right: -38px;
    top: 50%;
    margin-top: -7px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 7px 0 7px 7px;
		border-color: transparent transparent transparent white;
	}

	&:before {
		content: "";
		position: absolute;
		left: 0;
		width: 2px;
		height: 100%;
		background-color: $red;
		transition: width .3s cubic-bezier(0.22, 0.61, 0.36, 1);
		z-index: -1;
	}

	&:hover:before {
		width: 100%;
	}

	&:hover {
		color: white;
		z-index: 50;
		background-color: white
	}
}

.container {		
	padding: 0 4%;
	margin: 0 auto;
	max-width: 1290px;
}

aside > div {
	border-top: 2px solid $red;
	position: relative;
	padding-bottom: 40px;
	background-color: white;
	box-shadow: 1px 1px 5px 1px hsl(0, 0%, 80%);
}

/*@media (min-width: $sm) {
	.container {		
		padding: 0 30px;
	}
}

@media (min-width: $md) {
	.container {		
		padding: 0 60px;
	}		
}*/

@media (min-width: $lg) {
	/*.container {		
		padding: 0 80px;
	}*/
	main {
		lost-column: 66.666/100 auto 50px;			
	}
	aside {
		lost-column: 33.333/100 1 50px;
		float: right !important;
	}
}