.prefooter {
	
	margin-top: 60px;
	height: 360px;
	background-image: url(../img/prefooter_sm.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	text-align: center;

	&__inner {
		position: relative;
	}

	.button {
		position: absolute;
		top: 75px;
		left: 0;
	}

	@media(min-width: 500px) {
		background-image: url(../img/prefooter_md.jpg);
	}

	@media(min-width: 1000px) {
		background-image: url(../img/prefooter_lg.jpg);
	}

}