.calendar {
    h2 {
        padding-top: 23px;
        padding-bottom: 11px;
    }
    hr {
        margin-bottom: 20px;
    }
    &:before {
        content: "";
        position: absolute;
        top: -2px;
        right: 0;
        width: 50%;
        height: 2px;
        background-color: $blue;
    }
    .bg {
        width: 100%;
        height: 130px;
        background-image: url(../img/calendar.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
    }
    .month {
        padding: 25px 20px 0 20px;
        a {
            text-decoration: none;
            color: $font;
        }
        a:hover {
            color: $font;
        }
        h3 {
            padding-left: 10px
        }
        li {
            height: 30px;
            padding-left: 10px;
            list-style: none;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 30px;
            position: relative;
            cursor: pointer;
            &:before {
                content: "";
                position: absolute;
                left: 10px;
                width: 2px;
                height: 100%;
                background-color: $red;
                transition: width .3s cubic-bezier(0.22, 0.61, 0.36, 1);
            }
            &:hover:before {
                width: 90%;
            }
            &:hover span:first-child {
                color: white;
                z-index: 50;
            }
            span:first-child {
                padding-left: 10px;
                position: relative;
            }
            span:last-child {
                background-color: $red;
                color: white;
                padding: 0 20px 0 15px;
                position: absolute;
                right: -28px;
                min-width: 54px;
            }
            span:last-child:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-bottom: 6px solid $reddark;
                border-left: 8px solid transparent;
                bottom: -6px;
                right: 0px;
                transform: rotate(179deg);
                /*border-style: solid;
		    border-width: 7px 7px 6px 0;
		    border-color: transparent $reddark transparent transparent;
		    bottom: -8px;
		    right: 3px;
		    transform: rotate(46deg);*/
            }
        }
        li.calendar-recommended {
            span:last-child {
                background-color: $blue;
            }
            span:last-child:after {
                border-bottom: 6px solid $bluedark;
            }
            &:before {
                background-color: $blue;
            }
        }
    }
    @media screen and (min-width: $sm) and (max-width: $lg - 1) {
        display: flex;
        flex-wrap: wrap;
        h2 {
            width: 100%;
        }
        .month {
            width: 50%;
            display: inline-block;
        }
        .month li span:last-child {
            right: 10px
        }
        .month span:last-child:after {
            display: none
        }
    }
    @media (min-width: $lg) {
        display: block;
        .month {
            width: 100%;
            display: block;
        }
    }


}

.banner {
    background-image: url(../img/banner1.jpg);
    background-size: cover;
    height: 215px;
    background-repeat: no-repeat;
    margin-top: 30px;
    background-position: center;
    box-shadow: 1px 1px 5px 1px #ccc;
}
