.hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    .container {
        max-width: 1360px;
        padding-top: 90px;
    }
    .slideshow,
    .slideshow div {
        display: none;
    }
    article {
        padding-top: 60px;
    }
    h1 {
        float: left;
        display: inline-block;
        padding: 0 25px;
        font-size: 20px;
        background-color: $red;
        color: white;
        font-family: copper;
        line-height: 60px;
        margin: 0
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        font-size: 0;
        margin-bottom: 100px;
        /*transition: max-height 2s 0s, max-width 4s 2s;*/
    }
    .p-wrapper {
        padding: 30px 25px 0 25px;
        font-size: 16px;
        border-bottom: 30px solid transparent;
        background-color: rgba(0, 0, 0, 0.6);
        max-height: 258px;
        overflow: hidden;
    }
    .reveal-button {
        flex: 1;
        align-self: flex-end;
        max-width: 30px;
        background-color: $red;
        min-width: 120px;
        height: 30px;
        line-height: 30px;
        margin: 0;
        margin-bottom: -30px;
        vertical-align: text-top;
    }
    .reveal-button:hover {
        /*background-color: $buttongrey;		*/
    }
    p {
        position: relative;
        margin: 0;
        display: inline-block;
        color: white;
        text-overflow: ellipsis;
        text-shadow: 1px 1px grey;
    }
    .p-wrapper:nth-child(2) {
        display: none;
    }
    /*.wrapper.expand {
    max-height: 2500px;
	}*/
    .wrapper.expand .p-wrapper {
        max-height: 1000px;
        display: inline-block;
    }
    .wrapper.expand .p-wrapper:nth-child(1) {
        vertical-align: top;
    }
}

@media (min-width: 400px) {
    .hero {
        h1 {
            font-size: 24px
        }
    }
}

@media (min-width: 850px) {
    .hero {
        background-image: none;
        min-height: 500px;
    }
    h1 {
        /*width: calc(50% - 65px);*/
        text-align: center;
        font-size: 28px;
    }
    .p-wrapper {
        width: calc(50% - 65px);
        min-width: 400px;
        display: inline-block;
        /*transition: all .3s;*/
    }
    .p-wrapper:nth-child(2) {
        margin-left: 10px;
    }
    .wrapper.expand .p-wrapper {
        width: calc(50% - 65px);
        max-height: 1000px;
        display: inline-block;
        min-width: auto;
    }
}

@media (min-width: 1000px) {
    .hero {
    	background-image: none !important;
        .slideshow {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            z-index: -1;
        }
        .slideshow div {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            color: transparent;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity .5s;
            z-index: -1;
            /*transition: opacity .5s;*/
            animation: imageAnimation 72s linear infinite 0s;
        }
        .slideshow div:nth-child(1) {

        }
        .slideshow div:nth-child(2) {
            animation-delay: 8s;
        }
        .slideshow div:nth-child(3) {
            animation-delay: 16s;
        }
        .slideshow div:nth-child(4) {
		animation-delay: 24s;
		}
		.slideshow div:nth-child(5) {
		    animation-delay: 32s;
		}
        .slideshow div:nth-child(6) {
            animation-delay: 40s;
        }
        .slideshow div:nth-child(7) {
            animation-delay: 48s;
        }
        .slideshow div:nth-child(8) {
            animation-delay: 56s;
        }
        .slideshow div:nth-child(9) {
            animation-delay: 65s;
        }


        @keyframes imageAnimation {
            0% {
                opacity: 0;
            }
            0.5%{
                opacity: 1;
            }
            10.5% {
                opacity: 1;
            }
            11% {
                opacity: 0
            }
            100% {
                opacity: 0
            }
        }
    }

}

@media (min-width: 1250px) {
    .hero {
        h1 {
            width: auto;
            font-size: 32px;
        }
        article {
            overflow: hidden;
        }
    }
}

// .hero--empty {
//     min-height: 300px;
// }
