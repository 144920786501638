footer {
    background-color: $footergrey;
    .footer-column.logo {
        display: none;
    }
    .footer-column.icons {
        display: flex;
        justify-content: space-around;
        padding-top: 40px;
        span {
            display: none;
        }
    }
    .footer-column.watermark {
        img {
            display: block;
            margin: 0 auto;
        }
        .logo {
            margin-left: calc(50% - 85px);
        }
        hr {
            margin-bottom: 15px;
        }
    }
    .footer-disclaimer {
        height: 20px;
        background-color: black;
        text-align: center;
        vertical-align: bottom;
        display: inline-block;
        width: 100%;
        margin-bottom: -3px;
        a {
            color: white;
            text-decoration: none;
            display: block;
            font-size: 12px;
        }
    }
    @media (max-width: 599px) {
        .footer-column.watermark {
            margin-top: 30px;
            margin-bottom: 25px;
            .ideart {
                display: none;
            }
        }
        .container {
            padding: 0 50px;
        }
    }
    @media (max-width: 999px) {
        .footer-column.watermark {
        	text-align: center;
            .ideart {
                display: none;
            }
            .ideart-small {
                max-width: 50px;
                vertical-align: middle;
                display: inline-block;
            }
        }
    }
    @media (min-width: 600px) {
        height: 140px;
        .container {
            max-width: 1490px;
            padding-top: 27px;
            .footer-column {
                display: inline-block;
                lost-column: 1/6 auto 0;
                height: 85px;
                display: flex;
                align-items: center;
            }
            a {
                text-decoration: none;
            }
            .footer-column.logo {
                font-size: 0;
                display: block;
                &:after {
                    content: ' ';
                    display: inline-block;
                    vertical-align: middle;
                    /* vertical alignment of the inline element */
                    height: 100%;
                }
                .logo {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .footer-column.icons {
                lost-column: 2/3 auto 0;
                padding: 0;
                .icon-column {
                    lost-column: .3 auto 0;
                    svg {
                        display: inline;
                        vertical-align: bottom;
                    }
                    span {
                        color: white;
                        font-style: italic;
                        font-size: 14px;
                        padding-left: 20px;
                        margin-bottom: 3px;
                        letter-spacing: .7px;
                    }
                }
                .icon-column:nth-child(1) {
                    text-align: right;
                    span {
                        padding-left: 0;
                        padding-right: 20px;
                    }
                }
                .icon-column:nth-child(2) {
                    text-align: center;
                }
                .icon-column:nth-child(3) {
                    text-align: left;
                }
            }
            .footer-column.watermark {
                font-size: 0;
                display: block;
                text-align: right;
                .logo,
                hr {
                    display: none;
                }
                .ideart-small {}
                .ideart {}
                &:after {
                    content: ' ';
                    display: inline-block;
                    vertical-align: middle;
                    /* vertical alignment of the inline element */
                    height: 100%;
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .container .footer-column.watermark .ideart-small {
            display: none;
        }
        .container .footer-column.watermark .ideart {
            display: inline-block;
            vertical-align: middle;
            max-width: 140px;
        }
    }
    @media (min-width: 1150px) {
        .container .footer-column.icons .icon-column span {
            display: inline-block;
        }
    }
}
