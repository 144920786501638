.gallery {

	padding: 35px;

	li {
		list-style: none;
		lost-column: 1/2 auto 10px;

		&.remainder {
			padding-bottom: 34.7%;
			background-color: $buttongrey;
			position: relative;
			transition: background-color .3s;
			cursor: pointer;
		    margin-top: 8px;

			a {
				position: absolute;
		    height: 100%;
		    width: 100%;
		    z-index: 1;
			}

			&:hover {
				background-color: $red;
			}

			span {
					position: absolute;
					top: 50%;
			    left: 50%;
			    transform: translate(-50%, -50%);
			    font-size: 28px;
    			color: white;
					&::after {
						content: attr(data-remainder);
					}
				}
		}

		

	}

	
	@media (min-width: $sm) {

		li {

			lost-column: 1/4 auto 10px;

			&.remainder {
				padding-bottom: 16.8%;				
			}		

		}

	}


	@media (min-width: $md) {

		li {

			lost-column: 1/6 auto 10px;

			&.remainder {
				padding-bottom: 11%;				
			}		

		}

	}

	




}