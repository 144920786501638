.headroom {
    transition: transform 200ms linear;
}

.headroom--pinned {
    transform: translateY(0%);
}

.headroom--unpinned {
    transform: translateY(-100%);
}

.menu-open.headroom--unpinned {
    /*transform: translateY(0%);*/
}

header.main-header {
    height: 110px;
    z-index: 5;
    background-color: white;
    .container {
        max-width: 1600px;
        padding: 0 15px;
    }
    /**************
    *
    * logo
    *
    ***************/
    .logo {
        margin-top: 20px;
        float: left;
        position: relative;
        width: 330px;
    }

    .logo-mob {
        margin-top: 20px;
        float: left;
        position: relative;
        width: 120px;
    }
    /**************
    *
    * navigation
    *
    ***************/
    .main-nav {
        li a {
            display: inline-block;
            position: relative;
            letter-spacing: .7px;
            color: $font;
            text-decoration: none;
            &:hover {
                color: $red;
            }
        }
    }
    @media only screen and (max-height: 600px) and (max-width: $sm) {
        .menu-wrapper .main-nav {
            li {
                margin-bottom: 5px !important;
            }
            a {
                padding: 10px 0 2px !important;
                font-size: 17px;
            }
        }
        .menu-wrapper .languages {
            margin-top: 20px !important;
        }
    }
    @media only screen and (max-width: 599px) {
        .logo {
            display: none;
        }
    }
    @media only screen and (min-width: 600px) {
        .logo-mob {
            display: none;
        }
    }
    @media only screen and (max-width: 1099px) {
        
        .menu-button {
            position: relative;
            float: right;
            margin-top: 35px;
            width: 50px;
            height: 39px;
            padding: 10px;
            cursor: pointer;
            overflow: hidden;
            z-index: 100;
            color: white;
            &:hover div[class^="menu-button-icon"] {
                background-color: $red;
            }
        }
        .menu-button.button-active .menu-button-inner .menu-button-icon-1 {
            transform: translateY(0px) rotate(-45deg);
        }
        .menu-button.button-active .menu-button-inner .menu-button-icon-2 {
            opacity: 0;
        }
        .menu-button.button-active .menu-button-inner .menu-button-icon-3 {
            transform: translateY(0px) rotate(45deg);
        }
        .menu-button .menu-button-inner {
            position: relative;
            height: 100%;
            width: 100%;
        }
        .menu-button .menu-button-inner .menu-button-icon-1,
        .menu-button .menu-button-inner .menu-button-icon-2,
        .menu-button .menu-button-inner .menu-button-icon-3 {
            position: absolute;
            width: 100%;
            height: 2px;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background-color: $darkgrey;
            will-change: transform;
            transition: all 0.3s ease;
        }
        .menu-button .menu-button-inner .menu-button-icon-1 {
            transform: translateZ(0px) translateY(-7px);
        }
        .menu-button .menu-button-inner .menu-button-icon-3 {
            transform: translateZ(0px) translateY(7px);
        }
        >.container {
            width: 100%;
        }
        .mobile-header {
            height: 100%;
            width: 100%;
        }
        &.menu-open {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            background-color: transparent;
            height: 100%;
            transition: background-color 0.3s;
            transition-delay: .3s;
            transform: translateY(0%) !important;
            >.container {
                padding: 0;
            }
        }
        &.menu-open .menu-wrapper {
            visibility: visible;
            background-color: rgba(255, 255, 255, 0.95);
        }
        .menu-wrapper {
            position: fixed;
            display: table;
            width: 100%;
            height: 100%;
            background-color: rgba(214, 24, 31, 0);
            transition: all .3s;
            visibility: hidden;
            .logo {
                display: none;
            }
            .menu {
                display: table-cell;
                vertical-align: middle;
                .languages,
                .main-nav {
                    display: block;
                    float: none;
                    text-align: center;
                }
                .main-nav {
                    margin-top: 30px;
                    font-size: 19px;
                    font-weight: bold;
                    letter-spacing: .3px;
                    text-transform: uppercase;
                    opacity: 0;
                    &.fade {
                        opacity: 1;
                        transition: opacity .3s;
                        transition-delay: .3s;
                    }
                    li {
                        margin-bottom: 20px;
                        list-style: none;
                    }
                    li a {
                        display: inline-block;
                        padding: 5px 0 2px;
                        text-decoration: none;
                    }
                    li.current a {
                        color: $red;
                    }
                    li.parent a {
                        color: $red;
                    }
                }
                .languages {
                    margin: 0 auto;
                    opacity: 0;
                    width: 150px;
                    height: 80px;
                    &.fade {
                        opacity: 1;
                        transition: opacity .3s;
                        transition-delay: 0.3s;
                    }
                    li {
                        width: 40px;
                        height: 40px;
                        font-size: 17px;
                        line-height: 40px;
                        text-align: center;
                    }
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 700px) {
        .container {
            /*padding: 0 63px;*/
        }
    }
    @media only screen and (min-width: 1100px) {
        .logo-mob {
            display: none;
        }
        .mobile-header {
            display: none;
        }
        #menu-button {
            display: none;
        }
        .menu {
            float: right;
            position: relative;
        }
        .main-nav {
            display: inline-block;
            padding-right: 90px;
            ul,
            li {
                margin: 0;
            }
            ul {
                height: 110px;
            }
            li {
                display: inline-block;
                font-size: 16px;
                line-height: 110px;
                height: 100%;
                margin-right: 35px;
            }
            li:last-child {
                margin-right: 30px;
            }
            li a {
                text-decoration: none;
            }
            li.current a {
                color: $red;
            }
            li.parent a {
                color: $red;
            }
        }
        ul.languages {
            position: absolute;
            right: 0;
            float: none;
            bottom: -55px;
        }
    }
    @media only screen and (min-width: 1400px) {
        /*.logo {
        width: auto;
      }*/
        .main-nav {
            display: inline-block;
            /*li {
          margin-right: 70px;
        }*/
        }
    }
    /**************
    *
    * languages
    *
    ***************/
    ul.languages {
        display: inline-block;
        float: right;
        margin-right: -4px;
        width: 90px;
        height: 60px;
        font-size: 0;
        z-index: 100;
        &:after {
            content: ' ';
            display: inline-block;
            vertical-align: middle;
            /* vertical alignment of the inline element */
            height: 100%;
        }
        li,
        li > a {
            width: 30px;
            height: 30px;
            text-decoration: none;
        }
        li {
            display: inline-block;
            position: relative;
            font-size: 12px;
            font-weight: bold;
            line-height: 30px;
            text-align: center;
            vertical-align: bottom;
            background-color: rgba(0, 0, 0, .8);
            margin-bottom: 0;
            transition: background-color .3s;
        }
        li.active {
            vertical-align: top;
        }
        li a {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            color: white;
        }
        li:hover {
            background-color: $red;
        }
        li.active {
            background-color: $red;
        }
    }
}
